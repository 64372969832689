'use-client';
import * as React from 'react';
import { Image as PixelPerfectImage } from '@wix/image';
import type { ImageProps } from '@wix/image';
import styles from './style/Image.scss';
import classNames from 'clsx';
import type { ResponsiveImageProps } from '@wix/editor-elements-definitions';

const Image: React.FC<
  ImageProps & {
    responsiveImageProps?: ResponsiveImageProps;
    zoomedImageResponsiveOverride?: ResponsiveImageProps;
  }
> = props => {
  const {
    id,
    alt,
    role,
    className,
    imageStyles = {},
    targetWidth,
    targetHeight,
    onLoad,
    onError,
    containerWidth,
    containerHeight,
    isInFirstFold,
    socialAttrs,
    skipMeasure,
    responsiveImageProps,
    zoomedImageResponsiveOverride,
  } = props;

  const effectiveWidth = targetWidth || containerWidth;
  const effectiveHeight = targetHeight || containerHeight;

  const { fallbackSrc, srcset, sources, css } = responsiveImageProps || {};

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { width, height, ...responsiveImagesStyle } =
    responsiveImageProps?.css?.img || {};

  if (fallbackSrc && srcset && css) {
    return (
      <img
        // @ts-expect-error fetchpriority type should work in react > 18.3 https://github.com/facebook/react/pull/25927
        fetchpriority={isInFirstFold ? 'high' : undefined}
        loading={isInFirstFold === false ? 'lazy' : undefined}
        sizes={`${effectiveWidth}px`}
        srcSet={
          skipMeasure
            ? zoomedImageResponsiveOverride?.srcset
            : responsiveImageProps?.srcset
        }
        id={id}
        src={fallbackSrc}
        alt={alt || ''}
        role={role}
        style={{
          ...imageStyles,
          ...(skipMeasure
            ? { ...zoomedImageResponsiveOverride?.css?.img }
            : { ...responsiveImagesStyle }),
        }}
        onLoad={onLoad}
        onError={onError}
        className={classNames(className, styles.image)}
        width={effectiveWidth}
        height={effectiveHeight}
        {...socialAttrs}
      />
    );
  }

  if (fallbackSrc && sources && css) {
    return (
      <picture>
        {sources.map(
          ({
            srcset: sourceSrcset,
            media,
            sizes,
          }: {
            srcset: string;
            media: string;
            sizes: string;
          }) => {
            return (
              <source
                key={media}
                srcSet={sourceSrcset}
                media={media}
                sizes={sizes}
              />
            );
          },
        )}
        <img
          // @ts-expect-error fetchpriority type should work in react > 18.3 https://github.com/facebook/react/pull/25927
          fetchpriority={isInFirstFold ? 'high' : undefined}
          loading={isInFirstFold === false ? 'lazy' : undefined}
          id={id}
          src={sources[0].fallbackSrc}
          alt={alt || ''}
          role={role}
          style={{
            ...imageStyles,
            objectFit: sources[0].imgStyle.objectFit,
            objectPosition: sources[0].imgStyle.objectPosition,
          }}
          onLoad={onLoad}
          onError={onError}
          className={classNames(className, styles.image)}
          width={effectiveWidth}
          height={effectiveHeight}
          {...socialAttrs}
        />
      </picture>
    );
  }

  return <PixelPerfectImage {...props} />;
};

export default Image;
